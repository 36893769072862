@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme-reset($theme) { 
    $color-config:  mat.get-color-config($theme);
    $primary:       map.get($color-config, 'primary');
    $accent:        map.get($color-config, 'accent');
    $warn:          map.get($color-config, 'warn'); 
    $foreground:    map.get($color-config, 'foreground');
    $background:    map.get($color-config, 'background');

    .bg-primary { 
        background: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, default-contrast);
    }
    .bg-accent {
        background: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, default-contrast);
    }
    .bg-warn {
        background: mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn, default-contrast);
    }

    .text-muted {
        color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }

    .secondary-color{
        color: mat.get-color-from-palette($primary, 100);
    }

    .primary-color{
        color: mat.get-color-from-palette($primary);
    }

    .top-navbar.mat-toolbar,
    .app-dropdown .mat-menu-item,
    .app-dropdown .user-info,
    .account-sidenav .mat-nav-list .mat-list-item,
    .product-item .title,
    .mat-card .content p,
    .filter-sidenav .mat-expansion-panel-header-title,
    .mat-tab-body-content,
    .account-card-title {
         color: mat.get-color-from-palette($accent, darker) !important;
    }
    
    .logo-toolbar.mat-toolbar-row{
        background: black;
    }

    .top-toolbar.mat-toolbar-row{
        // border-bottom: 1px solid mat.get-color-from-palette($primary, lighter, 0.2);
        color: mat.get-color-from-palette($primary, 100);
        .top-menu a{
            color: mat.get-color-from-palette($primary, 100);
        }
    }

    .top-navbar{
       // background: mat.get-color-from-palette($primary, lighter);
       background: mat.get-color-from-palette($background, background);
    }

    .border-bottom-mute{
        border-bottom: 1px solid mat.get-color-from-palette($primary, lighter, 0.2);
    }


    .search-dropdown.mat-menu-panel{
        background: transparent;
    }


    .mat-snack-bar-container.success {
        background: #388E3C;
    }
    .mat-snack-bar-container.error {
        background: #E53935;
    }

    .new-price{
        color: mat.get-color-from-palette($primary);
    }
    .primary-text{
        color: mat.get-color-from-palette($primary);
    }

    .foreground-text{
        color: mat.get-color-from-palette($foreground, base); 
    }

    .active-link{
        background-color: mat.get-color-from-palette($primary); 
        color: #fff;
    }
    .horizontal-active-link,
    .app-dropdown .mat-menu-item.horizontal-active-link{
        color: mat.get-color-from-palette($primary) !important; 
    }

    .filter-brands  button.selected,
    .filter-buttons button.selected{
        box-shadow: 0px 0px 1px 2px mat.get-color-from-palette($primary);
    }  

    /* ngx-pagination */
    .product-pagination .ngx-pagination{
        margin-bottom: 0;
        padding: 14px 10px;
        .current { 
            background: mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, default-contrast);
        }
        a:hover, button:hover{
            background: rgba(mat.get-color-from-palette($primary), 0.2);
            color: mat.get-color-from-palette($foreground, base);
        }
    }

    .mat-toolbar {
        background: mat.get-color-from-palette($primary, 300);
        a{
            color: #fff;
        }
    }

    .info-bar {
        background: mat.get-color-from-palette($primary, 200);
        .mat-icon-lg {
            color: mat.get-color-from-palette($primary, 700);
        }

        .mat-card .content p {
            color: mat.get-color-from-palette($primary, 700) !important;
        }
    }

    .subscribe-block-container{
        background: mat.get-color-from-palette($primary, 300);
        h1, h3 {
            color: mat.get-color-from-palette($primary, 700);
        }
        .subscribe-button-container {
            background: #fff;
            .subscribe-button {
                border: 3px solid #fff;
                background: mat.get-color-from-palette($primary, 700);
                color: mat.get-color-from-palette($primary, 100) !important;
            }
        }
    }

    .item-title {
        color: mat.get-color-from-palette($primary, 700);
    }

    .banner-title-container {
        color: mat.get-color-from-palette($primary, 700);
    }

    .search-form{ 
        .search-btn{
            background: mat.get-color-from-palette($primary, 300);
            border: 3px solid #fff;
        }
    }
    
    // admin styles
    .breadcrumb{
        a{
            color: mat.get-color-from-palette($primary);
        }
        .breadcrumb-item+.breadcrumb-item:before{
            color: mat.get-color-from-palette($foreground, text);
        }
    } 
    .user-block{
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .menu-item .mat-button:not(.active-link) .menu-icon{
        color: mat.get-color-from-palette($primary);
    }

    .top-toolbar{ 
        .social-icon {
            color: mat.get-color-from-palette($primary, 100);
        }
    }
}