/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css'; 
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base";
@import "app/theme/styles/admin";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";
@import "app/theme/styles-custom/paid";
@import "./assets/fonts/li-fonts.scss";
