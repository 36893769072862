@font-face {
  font-family: 'litalian';
  src:
    url('litalian.woff2?c3qy8q') format('woff2'),
    url('litalian.ttf?c3qy8q') format('truetype'),
    url('litalian.woff?c3qy8q') format('woff'),
    url('litalian.svg?c3qy8q#litalian') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'litalian' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-li_warehouse:before {
  content: "\e908";
}
.icon-li_transport:before {
  content: "\e903";
}
.icon-li_loyalty:before {
  content: "\e900";
}
.icon-li_shopbag:before {
  content: "\e901";
}
.icon-li_clock:before {
  content: "\e902";
}
.icon-li_mail:before {
  content: "\e904";
}
.icon-li_newsletter:before {
  content: "\e905";
}
.icon-li_open_hours:before {
  content: "\e906";
}
.icon-li_phone:before {
  content: "\e907";
}
